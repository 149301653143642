import { handleActions } from 'redux-actions';
import produce from 'immer';

import Constants from '_app/constants';
import { MapFilterTabs } from '_app/constants/types';

import { FAIL, START, SUCCESS } from '../common';
import {
  CLEAR_MAP,
  FETCH_PROPERTY,
  FETCH_PROPERTY_INFO,
  PRESELECT_PROPERTY,
  SET_MAP_DRAW_MODE,
  SET_MAP_READY,
  UPDATE_MAP_CENTER,
  UPDATE_MAP_ZOOM,
  UPDATE_PROPERTY_FILTER,
} from './action';

const initialState = {
  filter: {
    tab: MapFilterTabs.NEW,
    bounds: null,
    searchAsMove: true,
  },
  mapCenter: { lat: 29.717971369549744, lng: -95.43397342215448 },
  mapZoom: Constants.DefaultMapZoom,
  mapReady: false,
  isMapDrawing: false,
  preSelected: null,
  selected: {
    loading: false,
    success: false,
    error: null,
    item: null,
  },
  loading: false,
  success: false,
  error: null,
  total: 0,
  items: [],
};

export default handleActions(
  {
    [FETCH_PROPERTY + START]: (state) =>
      produce(state, (draft) => {
        draft.loading = true;
        draft.success = false;
        draft.total = 0;
        draft.error = null;
      }),
    [FETCH_PROPERTY + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = true;
        draft.error = null;
        draft.total = payload.total_properties;
        draft.items = payload.properties;
      }),
    [FETCH_PROPERTY + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.loading = false;
        draft.success = false;
        draft.error = payload;
      }),
    [FETCH_PROPERTY_INFO + START]: (state) =>
      produce(state, (draft) => {
        draft.selected.loading = true;
        draft.selected.success = false;
        draft.selected.error = null;
        draft.selected.item = null;
      }),
    [FETCH_PROPERTY_INFO + SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected.loading = false;
        draft.selected.success = true;
        draft.selected.error = null;
        draft.selected.item = payload;
      }),
    [FETCH_PROPERTY_INFO + FAIL]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.selected.loading = false;
        draft.selected.success = false;
        draft.selected.error = payload;
      }),
    [PRESELECT_PROPERTY]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.preSelected = payload;
      }),
    [UPDATE_PROPERTY_FILTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.filter[payload.key] = payload.value;
        draft.preSelected = null;
      }),
    [UPDATE_MAP_CENTER]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.mapCenter = payload.location;
        if (payload.zoom) {
          draft.mapZoom = payload.zoom;
        }
      }),
    [UPDATE_MAP_ZOOM]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.mapZoom = payload;
      }),
    [SET_MAP_DRAW_MODE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.isMapDrawing = payload;
      }),
    [SET_MAP_READY]: (state) =>
      produce(state, (draft) => {
        draft.mapReady = true;
      }),
    [CLEAR_MAP]: (state) =>
      produce(state, (draft) => {
        draft.items = [];
        draft.preSelected = null;
        draft.filter.bounds = null;
      }),
  },
  initialState,
);
