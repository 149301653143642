import { Env } from '_app/config';
import MapSearch from './MapSearch';
import PropertyDetail from './PropertyDetail';

const ROUTES = [
  { path: `/${Env.PUBLIC_ID}/listing/:tab`, key: 'MapSearch', exact: true, component: MapSearch },
  { path: `/${Env.PUBLIC_ID}/details/:propertyId`, key: 'PropertyDetail', exact: true, component: PropertyDetail },
];

export default ROUTES;
