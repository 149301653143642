import { useCallback, useEffect } from 'react';

import Utils from '_app/utils';

export const DrawingPanel = ({ maps = null, map = null, onStartDrawing = () => {}, onCompleteDrawing = () => {} }) => {
  const allowMapDrag = useCallback(
    (draggable) => {
      map.setOptions({ draggable });
    },
    [map],
  );

  useEffect(() => {
    if (!maps || !map) {
      return;
    }
    let points = new maps.MVCArray();
    let originPolygon = null;
    let moveListener = null;

    const clearMap = () => {
      points.clear();
      if (originPolygon) {
        originPolygon.setMap(null);
      }
      maps.event.removeListener(moveListener);
    };

    const downListener = maps.event.addListener(map, 'mousedown', function (e) {
      onStartDrawing();
      allowMapDrag(false);
      clearMap();
      // Add first point to polygon
      points.push(e.latLng);
      originPolygon = Utils.createPolygon(map, maps, points);
      moveListener = maps.event.addListener(map, 'mousemove', function (em) {
        // Add points to polygon from mouse cursor position
        points.push(em.latLng);
      });
    });
    const upListener = maps.event.addListener(map, 'mouseup', function () {
      allowMapDrag(true);
      // TODO: check polygon self intersecting status before optimize

      // Optimize raw polygon points with simplify-js and redraw polygon
      const optimizedCoords = Utils.getOptimizeCoords(maps, points, map.getZoom());
      onCompleteDrawing(optimizedCoords);

      if (originPolygon) {
        // Remove raw polygon, unoptimized
        originPolygon.setMap(null);
      }
      maps.event.removeListener(moveListener);
    });
    return () => {
      clearMap();
      maps.event.removeListener(downListener);
      maps.event.removeListener(upListener);
    };
  }, [maps, map, allowMapDrag]);
  return null;
};
