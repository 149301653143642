import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from './Button';
import { Label } from './Label';
import { DrawingGuard } from './DrawingGuard';

import { ReactComponent as SearchIcon } from '_app/assets/images/search.svg';

import { getPropertySelector } from '_app/store/property/selector';
import { setMapDrawModeAction } from '_app/store/property/action';

export const ToolBar = () => {
  const dispatch = useDispatch();
  const { items = [], isMapDrawing } = useSelector(getPropertySelector);
  return (
    <div className="w-full h-12 flex-row flex border-b">
      <div className="flex flex-row justify-between items-center p-2 sm:border-r sm:w-1/2 lg:w-1/2 xl:w-1/3 relative">
        <div className="flex-row flex items-center">
          <Button label="FILTERS" leftIcon={<SearchIcon fill="white" />} className="bg-blue-600 text-sm text-white p-1 px-3 rounded" />
          {items.length > 0 && <Label label={`${items.length} RESULTS`} className="ml-4 hidden md:flex" />}
          <Button label="SAVE" className="ml-4 text-blue-600 hidden sm:flex" />
        </div>
        <div className="flex-row items-center hidden sm:flex">
          <Button label="SORT BY" className="text-blue-600" />
          <Button label="LIST" className="ml-4 text-blue-600" />
        </div>
        <DrawingGuard />
      </div>
      <div className="flex-1 w-full flex h-full flex-row justify-between items-center p-2">
        <div className="flex-row flex items-center">
          <div className="relative">
            <Button label="RESIZE MAP" className="text-blue-600 hidden md:flex" />
            <DrawingGuard />
          </div>
          <Button
            label={isMapDrawing ? 'CANCEL DRAW' : 'DRAW'}
            className="ml-4 text-blue-600 md:ml-8"
            onClick={() => dispatch(setMapDrawModeAction(!isMapDrawing))}
          />
          <div className="relative">
            <Button label="LAYERS" className="ml-4 text-blue-600" />
            <DrawingGuard />
          </div>
        </div>
        <div className="flex-row flex items-center relative">
          <Button label="SOLD" className="text-red-500" />
          <Button label="LIST" className="ml-4 text-blue-600 sm:hidden" />
          <DrawingGuard />
        </div>
      </div>
    </div>
  );
};
