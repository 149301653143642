import APIService from '_app/services/api';
import Utils from '_app/utils';
import axios from 'axios';
import { all, fork, put, select, take, takeLatest } from 'redux-saga/effects';

import { FAIL, START, SUCCESS } from '../common';
import { FETCH_PROPERTY, FETCH_PROPERTY_INFO } from './action';
import { getPropertyFilterSelector } from './selector';

let pfCancelToken = undefined;

function* fetchProperties() {
  const state = yield select();
  const { tab, bounds } = getPropertyFilterSelector(state);
  if (typeof pfCancelToken != typeof undefined) {
    pfCancelToken.cancel('Operation canceled due to new request.');
  }
  pfCancelToken = axios.CancelToken.source();
  try {
    const { data } = yield APIService.get(`/properties/${Utils.generateMapSearchParams(tab.apiEndpoint, bounds, true)}`, {
      cancelToken: pfCancelToken.token,
    });
    yield put({ type: FETCH_PROPERTY + SUCCESS, payload: { ...data } });
  } catch (error) {
    yield put({ type: FETCH_PROPERTY + FAIL, payload: error });
  }
}

function* fetchPropertyInfo() {
  while (true) {
    const { payload: mlsnum } = yield take(FETCH_PROPERTY_INFO + START);
    try {
      const { data } = yield APIService.get(`/properties/${mlsnum}`);
      yield put({ type: FETCH_PROPERTY_INFO + SUCCESS, payload: data });
    } catch (error) {
      yield put({ type: FETCH_PROPERTY_INFO + FAIL, payload: error });
    }
  }
}

export default function* sagas() {
  yield all([takeLatest([FETCH_PROPERTY + START], fetchProperties), fork(fetchPropertyInfo)]);
}
