import React, { memo } from 'react';
import Utils from '_app/utils';

const MarkerComponent = ({ price, preSelected, onClick }) => {
  return (
    <div
      className={`min-w-full cursor-pointer absolute ${preSelected ? 'z-50' : 'z-0'}`}
      style={{ transform: 'translate(-50%, -100%)' }}
      onClick={onClick}>
      <div
        className={`px-0.5 bg-green-600 shadow-inner text-white text-center py-0.5 pb-0 rounded items-center justify-center font-bold font-primary-bold text-xs ${
          preSelected ? 'border-2 border-blue-700 pb-0.5 pt-1 pl-1 pr-1 bg-pink-600' : ''
        }`}>
        <div className="z-50">${Utils.priceFormater(price)}</div>
      </div>
      <div className="-mt-03 justify-center flex">
        <div className={`w-2 h-2 transform rotate-45 bg-green-600 ${preSelected ? 'border-blue-700 border-b-2 border-r-2 bg-pink-600' : ''}`} />
      </div>
    </div>
  );
};

export const Marker = memo(MarkerComponent);
