import React, { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MapFilterTabs } from '_app/constants/types';
import { PropertyListItem } from './PropertyListItem';
import { LoadingView } from './LoadingView';
import { GoogleAutoComplete } from './GoogleAutoComplete';

import { getPropertySelector } from '_app/store/property/selector';
import { fetchPropertyAction, preSelectPropertyAction, updateMapCenterAction } from '_app/store/property/action';
import { Env } from '_app/config';
import { Link } from 'react-router-dom';

export const PropertyList = ({ onClickItem = () => {} }) => {
  const dispatch = useDispatch();
  const { preSelected, filter, items = [], loading, mapReady } = useSelector(getPropertySelector);

  const refs = items.reduce((acc, value) => {
    acc[value.mlsnum] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (filter.searchAsMove && mapReady) {
      dispatch(fetchPropertyAction());
    }
  }, [dispatch, filter, mapReady]);

  useEffect(() => {
    if (preSelected && preSelected.shouldScrollToItem && refs[preSelected.mlsnum].current) {
      refs[preSelected.mlsnum].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [preSelected, refs]);

  const onPreSelectItem = (item) => {
    dispatch(preSelectPropertyAction(item));
  };

  return (
    <div className="h-full flex flex-col w-full">
      <GoogleAutoComplete onChangeLocation={(location, zoom) => dispatch(updateMapCenterAction({ location, zoom }))} />
      <div className="flex flex-row justify-around py-2 bg-gray-200">
        {Object.keys(MapFilterTabs).map((tab, index) => (
          <Link
            key={index}
            to={`/${Env.PUBLIC_ID}/listing/${MapFilterTabs[tab].path}`}
            className={`text-white px-2 lg:px-4 font-primary text-sm rounded pt-0.5 ${
              MapFilterTabs[tab].label === filter.tab.label ? 'bg-blue-600 ' : 'bg-gray-400'
            }`}>
            {MapFilterTabs[tab].label}
          </Link>
        ))}
      </div>
      <div className="relative flex-1">
        <div className="flex-wrap absolute top-0 left-0 right-0 bottom-0 overflow-y-auto flex content-start">
          {items.map((item, index) => (
            <div
              className={`w-full lg:w-1/2 p-1 cursor-pointer bg-white hover:bg-gray-600 hover:text-white ${
                preSelected && preSelected.mlsnum === item.mlsnum ? 'bg-gray-600 text-white' : ''
              }`}
              key={index}
              ref={refs[item.mlsnum]}>
              <PropertyListItem
                key={index}
                item={item}
                preSelected={preSelected && preSelected.mlsnum === item.mlsnum}
                onMouseEnter={() => onPreSelectItem(item)}
                onClick={() => onClickItem(item)}
              />
            </div>
          ))}
        </div>
        {items.length === 0 && !loading && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-200">
            <div>No properties found.</div>
          </div>
        )}
        {loading && (
          <LoadingView size={120} stroke={4} color="white" label="Loading Properties..." labelStyle="text-white font-primary-bold text-xl" />
        )}
      </div>
    </div>
  );
};
