import React from 'react';
import Constants from '_app/constants';
import GoogleMapReact from 'google-map-react';
import { Env } from '_app/config';

export const PropertyDetailMap = ({ item }) => {
  const renderMarkers = (map, maps) => {
    new maps.Marker({
      position: { lat: item.latitude, lng: item.longitude },
      map,
    });
  };
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: Env.GOOGLE_API_KEY }}
      yesIWantToUseGoogleMapApiInternals={true}
      center={{ lat: item.latitude, lng: item.longitude }}
      zoom={18}
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      options={() => ({
        panControl: false,
        zoomControl: true,
        fullscreenControl: true,
        mapTypeControl: false,
        mapTypeId: Constants.GoogleMapTypes.ROADMAP,
      })}
    />
  );
};
