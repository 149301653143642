import simplify from 'simplify-js';

const POLYLINE_TOLERANCE = 0.01;
const STROKE_COLOR = 'blue';
const STROKE_OPACITY = 1.0;
const STROKE_WIDTH = 0.5;
const STROKE_WIDTH_OPTIMIZED = 1;
const FILL_COLOR = 'blue';
const FILL_OPACITY = 0.1;

export const createPolygon = (map, maps, coords, optimized = false) => {
  return new maps.Polygon({
    map: map,
    paths: coords,
    strokeColor: STROKE_COLOR,
    strokeOpacity: STROKE_OPACITY,
    strokeWeight: optimized ? STROKE_WIDTH_OPTIMIZED : STROKE_WIDTH,
    fillColor: FILL_COLOR,
    fillOpacity: FILL_OPACITY,
    editable: false,
    draggable: false,
    clickable: false,
  });
};

export const getOptimizeCoords = (maps, points, zoom = 10) => {
  const coords = points.getArray().map((point) => ({ x: point.lat(), y: point.lng() }));
  const optimized = simplify(coords, POLYLINE_TOLERANCE * Math.pow(10, (10 - (zoom < 11 ? 11 : zoom)) / 2), true);
  return new maps.MVCArray(optimized.map(({ x, y }) => new maps.LatLng(x, y)));
};

export const getZoomFromViewPort = (viewport) => {
  const GLOBE_WIDTH = 256; // a constant in Google's map projection
  const west = viewport.getSouthWest().lng();
  const east = viewport.getNorthEast().lng();

  let angle = east - west;
  if (angle < 0) {
    angle += 360;
  }
  return Math.round(Math.log((GLOBE_WIDTH * 360) / angle / GLOBE_WIDTH) / Math.LN2) + 2;
};

export const fitMapToBounds = (maps, map, points) => {
  const bounds = new maps.LatLngBounds();
  points.forEach((point) => bounds.extend(point));
  map.fitBounds(bounds);
};
