import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from './Logo';

const menu_items = [
  { label: 'TOOLS', href: '#' },
  { label: 'ABOUT', href: '#' },
];

export const NavBar = () => {
  return (
    <div className="flex flex-row justify-between px-4 w-full bg-white border-b items-center">
      <Logo />
      <ul className="flex-1 flex flex-row justify-end items-center">
        {menu_items.map(({ label, href }) => (
          <li className="px-2 py-4 hover:bg-blue-600 hover:text-white cursor-pointer font-primary-bold text-blue-600" key={label}>
            <Link to={href}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
