import React from 'react';
import { Link } from 'react-router-dom';
import { FlexRow } from './FlexRow';
import { Label } from './Label';

const cellStyle = 'border border-gray-200 p-2';
const SCHOOL_INFO =
  'School information is generated by the most current available data we have. However, as school boundary maps can change, and schools can get too crowded (whereby students zoned to a school may not be able to attend in a given year if they are not registered in time), you need to independently verify and confirm enrollment and all related information directly with the school.';

export const SchoolTable = ({ property }) => (
  <div className="mt-12">
    <Label
      label={`School (District: ${property.city.toUpperCase()} - ${property.school_district})`}
      className="font-primary-bold text-xl text-blue-600"
    />
    <table className="w-full table-auto mt-3 border-collapase border border-gray-200 text-left">
      <thead>
        <tr>
          <th className={cellStyle}>Name</th>
          <th className={cellStyle}>Grade</th>
          <th className={cellStyle}>GreatSchool Ranking</th>
          <th className={cellStyle}>Performance Index</th>
          <th className={cellStyle}>Distinction Designations</th>
        </tr>
      </thead>
      <tbody>
        {property.schools.map(({ name, grade, greatschools_ranking, har_performance_index, har_distinctions }, index) => (
          <tr key={index} className={`px-3 text-left ${index % 2 === 0 ? 'bg-blue-50' : ''}`}>
            <td className={cellStyle}>{name}</td>
            <td className={cellStyle}>{grade}</td>
            <td className={cellStyle}>{`${greatschools_ranking ? greatschools_ranking + ' of 10' : 'Unknown'}`}</td>
            <td className={cellStyle}>{`${har_performance_index ? har_performance_index + ' of 4' : 'Unknown'}`}</td>
            <td className={cellStyle}>{`${har_distinctions ? har_distinctions + ' of 7' : 'Unknown'}`}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <FlexRow className="justify-center mt-6">
      <Link to="https://www.houstonproperties.com/home-guide/best-houston-schools" rel="noreferrer" target="_blank">
        <Label label="Read more about Houston's Top Schools" className="font-primary-bold text-base flex-1 text-blue-600" />
      </Link>
    </FlexRow>
    <FlexRow className="justify-center mt-4">
      <Link to="https://www.houstonproperties.com/home-guide/best-houston-schools" rel="noreferrer" target="_blank">
        <Label label="Modify your search to INCLUDE or EXCLUDE specific schools" className="font-primary-bold text-base flex-1 text-blue-600" />
      </Link>
    </FlexRow>
    <FlexRow className="p-4 mx-4 mt-4 bg-red-50 border-l-4 border-red-300 text-sm font-primary-thin">{SCHOOL_INFO}</FlexRow>
  </div>
);
