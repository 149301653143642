import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../Button';
import { FlexCol } from '../FlexCol';
import { FlexRow } from '../FlexRow';
import { HR } from '../HR';
import { ImageSlider } from '../ImageSlider';
import { Label } from '../Label';
import { LoadingView } from '../LoadingView';
import { SchoolTable } from '../SchoolTable';
import { BaseModal } from './BaseModal';
import { FullScreenImageModal } from './FullScreenImageModal';

import Constants from '_app/constants';
import Utils from '_app/utils';

import { getSelectedPropertySelector } from '_app/store/property/selector';
import { Env } from '_app/config';
import { Link } from 'react-router-dom';

export const PropertyInfoModal = ({ visible, onClose = () => {} }) => {
  const { loading, item } = useSelector(getSelectedPropertySelector);
  const [showFullImage, setShowFullImage] = useState(false);

  return (
    <BaseModal visible={visible} className="flex-1 max-w-screen-sm w-screen h-full outline-none mt-2 font-primary" onClose={onClose}>
      {!loading && !!item && (
        <FlexCol className="bg-white w-full flex-1 pb-20">
          <ImageSlider images={item.photos} enableDragging={false} onClick={() => setShowFullImage(true)} />
          <div className="px-3 my-5">
            <div className="bg-yellow-50 rounded border border-yellow-100 px-3 py-2 text-yellow-600 text-base">
              Love this property? Schedule an appointment to see it today!
            </div>
          </div>
          <FlexRow className="px-3">
            <Link className="flex-1" to={`/${Env.PUBLIC_ID}/details/${item.mlsnum}`} rel="noreferrer" target="_blank">
              <div className="font-primary-bold text-blue-600 text-xl leading-5">{item.address}</div>
              <div className="text-lg text-gray-900">
                {item.city}, {item.zip}
              </div>
            </Link>
            <div>
              <Link to={`/${Env.PUBLIC_ID}/details/${item.mlsnum}`} rel="noreferrer" target="_blank">
                <Button label="Full details" className="text-blue-600" />
              </Link>
              <Button label="Street View" className="text-blue-600" />
            </div>
          </FlexRow>
          <HR className="mt-2" />
          <FlexCol className="px-3 py-2">
            <Label className="font-primary-bold text-xl" label={`$ ${(item.price || 0).toLocaleString()}`} />
            <FlexRow>
              <FlexCol className="flex-1">
                <FlexRow>
                  <Label label={item.bedrooms} className="mr-2 font-primary-bold text-base leading-5" />
                  <Label label="Beds" className="text-gray-700 font-thin text-base leading-5" />
                </FlexRow>
                <FlexRow>
                  <Label label={item.full_bathrooms} className="mr-2 font-primary-bold text-base leading-5" />
                  <Label label="Full bath" className="text-gray-700 font-thin text-base leading-5" />
                </FlexRow>
                <FlexRow>
                  <Label label={item.half_bathrooms} className="mr-2 font-primary-bold text-base leading-5" />
                  <Label label="Half bath" className="text-gray-700 font-thin text-base leading-5" />
                </FlexRow>
              </FlexCol>
              <FlexCol className="flex-1">
                <FlexRow>
                  <Label label={item.building_size.toLocaleString()} className="mr-2 font-primary-bold text-base leading-5" />
                  <Label label="Building Sqft." className="font-primary-bold text-base leading-5" />
                </FlexRow>
                <FlexRow>
                  <Label label={item.lot_size.toLocaleString()} className="mr-2 font-primary-bold text-base leading-5" />
                  <Label label="Lot Sqft." className="font-primary-bold text-base leading-5" />
                </FlexRow>
                <FlexRow>
                  <Label label={item.property_type} className="mr-2 font-primary-bold text-base leading-5" />
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexCol>
          <div className="px-3">
            <HR className="my-6" />
            <Label label="General Description" className="font-primary-bold text-xl text-blue-600" />
            <Label label={Constants.SAMPLE_SENTENCE} className="text-base" />
          </div>
          {Utils.generatePropertyGeneralRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Taxes & Fees" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyTaxRows(item).map(({ value, label, isLink }, index) => (
            <div key={index} className="px-3">
              {!isLink && <HR className="my-3" />}
              <FlexRow className={isLink ? 'justify-center mt-4' : ''}>
                {isLink && (
                  <Link to={value} rel="noreferrer" target="_blank">
                    <Label label={label} className="font-primary-bold text-base flex-1 text-blue-600" />
                  </Link>
                )}
                {!isLink && (
                  <>
                    <Label label={label} className="font-primary-bold text-base flex-1" />
                    <Label label={value} className="text-base flex-1" />
                  </>
                )}
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Room/Lot Size" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyRoomRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Interior Features" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyInteriorRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3 mt-12">
            <Label label="Exterior Features" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyExteriorRows(item).map(({ value, label }, index) => (
            <div key={index} className="px-3">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="px-3">
            <SchoolTable property={item} />
          </div>
        </FlexCol>
      )}
      {loading && <LoadingView />}
      <button className="top-2 left-2 fixed bg-gray-200 text-black px-6 py-1 rounded font-primary-bold" onClick={onClose}>
        back
      </button>
      {showFullImage && <FullScreenImageModal visible={showFullImage} slideIndex={0} images={item.photos} onClose={() => setShowFullImage(false)} />}
    </BaseModal>
  );
};
