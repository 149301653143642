import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import propertyReducer from './property/reducer';

export default function rootReducer(history) {
  return combineReducers({
    property: propertyReducer,
    router: connectRouter(history),
  });
}
