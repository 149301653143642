import React from 'react';
import LOADING_ICON from '_app/assets/images/loading';

export const LoadingView = ({ label, size = 100, stroke, color, labelStyle = '' }) => {
  return (
    <div className="absolute bg-black bg-opacity-40 inset-0 flex flex-col justify-center items-center">
      {!!label && <div className={labelStyle}>{label}</div>}
      <LOADING_ICON width={size} height={size} stroke={stroke} color={color} />
    </div>
  );
};
