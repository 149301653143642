export const googleAutocomplete = async (text) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject('Need valid text input');
    }
    if (typeof window === 'undefined') {
      return reject('Need valid window object');
    }
    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions({ input: text }, resolve);
    } catch (e) {
      reject(e);
    }
  });

export const googlePlaceDetails = async (placeId, ref) =>
  new Promise((resolve, reject) => {
    if (!placeId) {
      return reject('Need valid place id');
    }
    if (typeof window === 'undefined') {
      return reject('Need valid window object');
    }
    try {
      new window.google.maps.places.PlacesService(ref).getDetails({ placeId }, resolve);
    } catch (e) {
      reject(e);
    }
  });
