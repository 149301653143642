export const priceFormater = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  return num;
};

export const generateMapSearchParams = (tab, bounds, enableBounds) => {
  let query = `${tab}/?page=1`;
  if (!enableBounds) {
    return query;
  }
  if (bounds) {
    if (bounds instanceof Array) {
      const lats = bounds.map((coord) => coord.lat());
      const longs = bounds.map((coord) => coord.lng());
      query = query + `&lat=${[...lats, lats[0]].join('|')}&long=${[...longs, longs[0]].join('|')}`;
    } else {
      const lats = Object.keys(bounds).map((key) => bounds[key].lat);
      const longs = Object.keys(bounds).map((key) => bounds[key].lng);
      query = query + `&lat=${[...lats, lats[0]].join('|')}&long=${[...longs, longs[0]].join('|')}`;
    }
  }
  return query;
};
