import axios from 'axios';
import { Env } from '_app/config';

const config = {
  baseURL: Env.API_URL,
};
const APIService = axios.create(config);

APIService.interceptors.request.use((request) => {
  request.headers['X-Public-Id'] = Env.PUBLIC_ID;
  return request;
});

export default APIService;
