import React from 'react';

import { ImageSlider } from '../ImageSlider';
import { BaseModal } from './BaseModal';

import { ReactComponent as NextIcon } from '_app/assets/images/arrow_right.svg';
import { ReactComponent as PrevIcon } from '_app/assets/images/arrow_left.svg';
import { ReactComponent as CloseIcon } from '_app/assets/images/close.svg';

export const FullScreenImageModal = ({ visible, onClose = () => {}, slideIndex = 1, images = [] }) => {
  const rightButton = ({ nextSlide }) => (
    <div className="bg-black bg-opacity-50 rounded-full p-4 mr-4 cursor-pointer" onClick={nextSlide}>
      <NextIcon width={40} height={40} className="text-white " fill="white" />
    </div>
  );
  const leftButton = ({ previousSlide }) => (
    <div className="bg-black bg-opacity-50 rounded-full p-4 ml-4 cursor-pointer" onClick={previousSlide}>
      <PrevIcon width={40} height={40} className="text-white " fill="white" />
    </div>
  );

  return (
    <BaseModal
      visible={visible}
      className="flex-1 w-screen bg-gray-800 bg-opacity-95 h-full outline-none font-primary flex justify-center items-center"
      onClose={onClose}>
      <ImageSlider
        images={images}
        slideIndex={slideIndex}
        rightButton={rightButton}
        leftButton={leftButton}
        onClose={onClose}
        imageContainer="w-full h-screen flex justify-center items-center relative"
      />
      <button className="top-2 left-0 fixed text-white px-6 py-1 text-lg flex font-primary-thin" onClick={onClose}>
        <CloseIcon width={24} height={24} fill="white" />
        <span>Close</span>
      </button>
    </BaseModal>
  );
};
