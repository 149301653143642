import React from 'react';
import { useSelector } from 'react-redux';

import { getPropertySelector } from '_app/store/property/selector';

export const DrawingGuard = () => {
  const { isMapDrawing } = useSelector(getPropertySelector);
  if (isMapDrawing) {
    return <div key="overlay" className="absolute inset-0 bg-white bg-opacity-60 z-50" />;
  } else {
    return null;
  }
};
