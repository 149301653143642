import Carousel from 'nuka-carousel';

export const ImageSlider = ({
  images,
  slideIndex = 0,
  enableDragging = true,
  imageContainer,
  rightButton = null,
  leftButton = null,
  onClick = () => {},
  onClose = () => {},
}) => {
  return (
    <div
      className="w-full h-full"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}>
      <Carousel
        renderBottomCenterControls={() => null}
        dragging={enableDragging}
        slideIndex={slideIndex}
        renderCenterLeftControls={leftButton}
        renderCenterRightControls={rightButton}>
        {images.map((image, index) => (
          <div key={index} className={imageContainer}>
            <img src={image.url} alt="property-image" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
