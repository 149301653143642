import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore, { history } from '_app/store';

import './styles/tailwind.css';
import './styles/global.css';
import './styles/custom.css';

import Pages from '_app/pages';
import { ConnectedRouter } from 'connected-react-router';

const App = () => {
  const [rootStore, setRootStore] = useState(undefined);

  useEffect(() => {
    const initStore = async () => {
      const { store, persistor } = await configureStore();
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <Provider store={rootStore?.store}>
      <PersistGate loading={null} persistor={rootStore.persistor}>
        <ConnectedRouter history={history}>
          <Pages />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
