import { createSelector } from 'reselect';

const getPropertyState = ({ property }) => property;

export const getPropertySelector = createSelector([getPropertyState], (property) => property);
export const getPreSelectedPropertySelector = createSelector([getPropertyState], ({ preSelected }) => preSelected);
export const getSelectedPropertySelector = createSelector([getPropertyState], ({ selected }) => selected);
export const getPropertyFilterSelector = createSelector([getPropertyState], ({ filter }) => filter);
export const mapCenterSelector = createSelector([getPropertyState], ({ mapCenter }) => mapCenter);
export const mapZoomSelector = createSelector([getPropertyState], ({ mapZoom }) => mapZoom);
