import React from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

import { NavBar } from '_app/components';
import { Env } from '_app/config';
import { MapFilterTabs } from '_app/constants/types';
import ROUTES from './routes';

const AvailableMapSearchPaths = Object.keys(MapFilterTabs).map((key) => MapFilterTabs[key]);

const Pages = () => {
  return (
    <div className="h-full w-full flex flex-col">
      <NavBar />
      <Switch>
        {ROUTES.map((route, index) => (
          <Route key={index} {...route} />
        ))}
        <Redirect to={`/${Env.PUBLIC_ID}/listing/${AvailableMapSearchPaths[0].path}`} />
      </Switch>
    </div>
  );
};

export default Pages;
