import React from 'react';
import { Link } from 'react-router-dom';

import LOGO from '_app/assets/images/logo.png';

export const Logo = () => {
  return (
    <Link to="/">
      <img src={LOGO} width="100" height="50" alt="logo" />
    </Link>
  );
};
