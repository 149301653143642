import React, { useCallback, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';

import Constants from '_app/constants';
import { MapControls } from './MapControls';
import { DrawingPanel } from './DrawingPanel';
import { Marker } from './Marker';
import Utils from '_app/utils';

import { getPropertySelector } from '_app/store/property/selector';
import {
  clearMapAction,
  fetchPropertyAction,
  preSelectPropertyAction,
  setMapDrawModeAction,
  setMapReadyAction,
  updateMapCenterAction,
  updateMapZoomAction,
  updatePropertyFilterAction,
} from '_app/store/property/action';
import { Env } from '_app/config';

let map = null;
let maps = null;
let polygon = null;

export const MapBoard = ({ onClickMarker = () => {} }) => {
  const dispatch = useDispatch();
  const [mapType, setMapType] = useState(Constants.GoogleMapTypes.ROADMAP);
  const { items, preSelected, mapCenter, mapZoom, isMapDrawing, filter, mapReady } = useSelector(getPropertySelector);
  const { searchAsMove } = filter;

  const handleApiLoaded = useCallback(
    (mapRef, mapsRef) => {
      map = mapRef;
      maps = mapsRef;
      dispatch(setMapReadyAction());
    },
    [dispatch],
  );

  const onClickMapMarker = useCallback(
    (item) => {
      if (!preSelected || preSelected.mlsnum !== item.mlsnum) {
        dispatch(preSelectPropertyAction({ ...item, shouldScrollToItem: true }));
      } else {
        onClickMarker(item);
      }
    },
    [dispatch, onClickMarker, preSelected],
  );

  const cleanPolygon = () => {
    dispatch(clearMapAction());
    if (polygon) {
      polygon.setMap(null);
      polygon = null;
    }
  };

  const drawFinalPolygon = (coords) => {
    if (!map || !maps) {
      return;
    }
    cleanPolygon();
    polygon = Utils.createPolygon(map, maps, coords, true);
    dispatch(updatePropertyFilterAction({ key: 'bounds', value: coords.getArray() }));
    dispatch(setMapDrawModeAction(false));
    if (!searchAsMove) {
      dispatch(fetchPropertyAction());
    }
    Utils.fitMapToBounds(maps, map, coords.getArray());
  };

  const onMapChange = ({ center, zoom }) => {
    dispatch(updateMapCenterAction({ location: center, zoom }));
    if (polygon) {
      return;
    }
  };

  return (
    <div className="flex-1 relative">
      <GoogleMapReact
        bootstrapURLKeys={{ key: Env.GOOGLE_API_KEY }}
        yesIWantToUseGoogleMapApiInternals={true}
        center={mapCenter}
        zoom={mapZoom}
        options={() => ({
          panControl: false,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          mapTypeId: mapType,
        })}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onChange={(data) => onMapChange(data)}
        // onZoomAnimationEnd={(level) => dispatch(updateMapZoomAction(level))}
      >
        {items.map((item) => (
          <Marker
            key={item.mlsnum}
            lat={item.latitude}
            lng={item.longitude}
            mlsnum={item.mlsnum}
            preSelected={preSelected && preSelected.mlsnum === item.mlsnum}
            price={item.price}
            onClick={() => onClickMapMarker(item)}
          />
        ))}
        {mapReady && isMapDrawing && <DrawingPanel map={map} maps={maps} onStartDrawing={cleanPolygon} onCompleteDrawing={drawFinalPolygon} />}
      </GoogleMapReact>
      <MapControls
        searchAsMove={searchAsMove}
        enableMapMoveSearch={() => dispatch(updatePropertyFilterAction({ key: 'searchAsMove', value: !searchAsMove }))}
        onChangeMapType={setMapType}
        onZoomIn={() => dispatch(updateMapZoomAction(mapZoom < 22 ? mapZoom + 1 : mapZoom))}
        onZoomOut={() => dispatch(updateMapZoomAction(mapZoom > 3 ? mapZoom - 1 : mapZoom))}
        onClear={cleanPolygon}
        searchCurrentBounds={() => dispatch(fetchPropertyAction())}
      />
    </div>
  );
};
