export const TAX_LINK = 'https://www.houstonproperties.com/home-guide/houston-homestead-exemption';

export const generatePropertyGeneralRows = (property) => {
  if (!property) {
    return [];
  }

  const data = [];
  if (property.price) {
    data.push({ label: 'Listing Price', value: `$${property.price.toLocaleString()}` });
  }
  if (property.address) {
    data.push({ label: 'Address', value: property.address });
  }
  if (property.city) {
    data.push({ label: 'City', value: property.city });
  }
  if (property.state) {
    data.push({ label: 'State', value: property.state });
  }
  if (property.zip) {
    data.push({ label: 'Zip Code', value: property.zip });
  }
  if (property.county) {
    data.push({ label: 'County', value: property.county });
  }
  if (property.subdivision) {
    data.push({ label: 'Subdivision', value: property.subdivision });
  }
  if (property.property_type) {
    data.push({ label: 'Property Type', value: property.property_type });
  }
  if (property.active) {
    data.push({ label: 'Listing Status', value: property.active ? 'Active' : 'Inactive' });
  }
  if (property.bedrooms) {
    data.push({ label: 'Bedrooms', value: property.bedrooms });
  }
  if (property.full_bathrooms || property.half_bathrooms) {
    data.push({ label: 'Baths', value: `${property.full_bathrooms || 0} Full & ${property.half_bathrooms || 0} Half Bath(s)` });
  }
  if (property.garage_spaces) {
    data.push({ label: 'Garage', value: property.garage_spaces });
  }
  if (property.stories) {
    data.push({ label: 'Stories', value: property.stories });
  }
  if (property.style) {
    data.push({ label: 'Style', value: property.style.join(', ') });
  }
  if (property.year_built) {
    data.push({ label: 'Year Built', value: property.year_built + ' / Appraisal District' });
  }
  if (property.building_size) {
    data.push({ label: 'Building Sq Ft', value: property.building_size.toLocaleString() + ' / Appraisal District' });
  }
  if (property.lot_size) {
    data.push({ label: 'Lot Size', value: property.lot_size.toLocaleString() + ' / Appraisal District' });
  }
  if (property.geo_market_area) {
    data.push({ label: 'Market Area', value: property.geo_market_area + ' Area' });
  }
  if (property.mlsnum) {
    data.push({ label: 'MLS#', value: property.mlsnum + ' (HAR)' });
  }
  if (property.area) {
    data.push({ label: 'Area', value: property.area });
  }
  if (property.days_on_market) {
    data.push({ label: 'Days on Market', value: property.days_on_market + ' days' });
  }
  if (property.cumulative_days_on_market) {
    data.push({ label: 'Total Days on Market', value: property.cumulative_days_on_market + ' days' });
  }
  if (property.price_sqft) {
    data.push({ label: 'List Price / Sq Ft', value: '$ ' + property.price_sqft.toLocaleString() });
  }
  return data;
};

export const generatePropertyTaxRows = (property) => {
  if (!property) {
    return [];
  }

  const data = [];
  if (property.tax_id) {
    data.push({ label: 'Tax ID', value: property.tax_id });
  }
  if (property.tax_amount && property.tax_year) {
    data.push({ label: 'Taxes w/o Exemption/Yr', value: '$' + property.tax_amount.toLocaleString() + ' / ' + property.tax_year });
  }
  if (property.tax_rate) {
    data.push({ label: 'Tax Rate', value: property.tax_rate.toLocaleString() + '%' });
  }
  if (property.maintenance_fee) {
    data.push({ label: 'Maint Fee', value: 'Yes / $' + property.maintenance_fee.toLocaleString() + ' ' + property.maintenance_fee_schedule });
  }
  data.push({ isLink: true, value: TAX_LINK, label: 'Read more about taxes & exemptions' });
  return data;
};

export const generatePropertyRoomRows = (property) => {
  const data = [];
  if (!property || !property.room_dimensions) {
    return data;
  }
  property.room_dimensions.forEach(({ room, dimensions }) => {
    data.push({ label: room, value: dimensions });
  });
  return data;
};

export const generatePropertyInteriorRows = (property) => {
  const data = [];
  if (!property) {
    return data;
  }
  if (property.floors) {
    data.push({ label: 'Floors', value: property.floors.join(', ') });
  }
  if (property.heating_system) {
    data.push({ label: 'Heating', value: property.heating_system.join(', ') });
  }
  if (property.cooling_system) {
    data.push({ label: 'Cooling', value: property.cooling_system.join(', ') });
  }
  if (property.connections) {
    data.push({ label: 'Connection', value: property.connections.join(', ') });
  }
  if (property.bedroom_description) {
    data.push({ label: 'Bedrooms', value: property.bedroom_description.join(', ') });
  }
  data.push({ label: 'Dishwasher', value: property.dishwasher ? 'Yes' : 'No' });
  data.push({ label: 'Range', value: property.range ? 'Yes' : 'No' });
  data.push({ label: 'Disposal', value: property.disposal ? 'Yes' : 'No' });
  data.push({ label: 'Microwave', value: property.microwave ? 'Yes' : 'No' });
  if (property.energy) {
    data.push({ label: 'Energy Feature', value: property.energy.join(', ') });
  }
  if (property.oven) {
    data.push({ label: 'Oven', value: property.oven.join(', ') });
  }
  if (property.interior) {
    data.push({ label: 'Interior', value: property.interior.join(', ') });
  }
  return data;
};

export const generatePropertyExteriorRows = (property) => {
  const data = [];
  if (!property) {
    return data;
  }
  if (property.foundation) {
    data.push({ label: 'Foundation', value: property.foundation.join(', ') });
  }
  if (property.roof) {
    data.push({ label: 'Roof', value: property.roof.join(', ') });
  }
  if (property.exterior_type) {
    data.push({ label: 'Exterior Type', value: property.exterior_type.join(', ') });
  }
  if (property.water_sewer) {
    data.push({ label: 'Water Sewer', value: property.water_sewer.join(', ') });
  }
  if (property.exterior) {
    data.push({ label: 'Exterior', value: property.exterior.join(', ') });
  }
  if (property.lot_description) {
    data.push({ label: 'Lot Description', value: property.lot_description.join(', ') });
  }
  if (property.listing_firm) {
    data.push({ label: 'Listing Firm', value: property.listing_firm });
  }
  return data;
};
