import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';

import { DrawingGuard, MapBoard, PropertyList, ToolBar } from '_app/components';
import { PropertyInfoModal } from '_app/components/modals';
import { MapFilterTabs } from '_app/constants/types';

import { fetchPropertyInfoAction, updatePropertyFilterAction } from '_app/store/property/action';

const AvailableMapSearchPaths = Object.keys(MapFilterTabs).map((key) => MapFilterTabs[key]);

const MapSearch = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [openPreview, setOpenPreview] = useState(false);
  useEffect(() => {
    const linkedTab = AvailableMapSearchPaths.find((item) => item.path === params.tab);
    if (linkedTab) {
      dispatch(updatePropertyFilterAction({ key: 'tab', value: linkedTab }));
    }
  }, [dispatch, params.tab]);

  const onOpenPreview = ({ mlsnum }) => {
    dispatch(fetchPropertyInfoAction(mlsnum));
    setOpenPreview(true);
  };

  return (
    <div className="flex flex-1 flex-col justify-center items-center font-primary">
      <ToolBar />
      <div className="flex-1 w-full flex h-full flex-row">
        <div className="w-1/3 border-r hidden md:flex flex-col relative">
          <PropertyList onClickItem={onOpenPreview} />
          <DrawingGuard />
        </div>
        <MapBoard onClickMarker={onOpenPreview} />
      </div>
      <PropertyInfoModal visible={openPreview} onClose={() => setOpenPreview(false)} />
    </div>
  );
};

export default MapSearch;
