import { createAction } from 'redux-actions';
import { START } from '../common';

export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const FETCH_PROPERTY_INFO = 'FETCH_PROPERTY_INFO';
export const PRESELECT_PROPERTY = 'PRESELECT_PROPERTY';
export const UPDATE_PROPERTY_FILTER = 'UPDATE_PROPERTY_FILTER';
export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER';
export const UPDATE_MAP_ZOOM = 'UPDATE_MAP_ZOOM';
export const SET_MAP_DRAW_MODE = 'SET_MAP_DRAW_MODE';
export const SET_MAP_READY = 'SET_MAP_READY';
export const CLEAR_MAP = 'CLEAR_MAP';

export const fetchPropertyAction = createAction(FETCH_PROPERTY + START);
export const fetchPropertyInfoAction = createAction(FETCH_PROPERTY_INFO + START);
export const preSelectPropertyAction = createAction(PRESELECT_PROPERTY);
export const updatePropertyFilterAction = createAction(UPDATE_PROPERTY_FILTER);
export const updateMapCenterAction = createAction(UPDATE_MAP_CENTER);
export const updateMapZoomAction = createAction(UPDATE_MAP_ZOOM);
export const setMapDrawModeAction = createAction(SET_MAP_DRAW_MODE);
export const setMapReadyAction = createAction(SET_MAP_READY);
export const clearMapAction = createAction(CLEAR_MAP);
