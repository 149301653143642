import { useState, useEffect, useRef } from 'react';

export const useOutSideClick = (initialIsVisible) => {
  const [clickedOutside, setClickedOutside] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return { ref, clickedOutside, setClickedOutside };
};
