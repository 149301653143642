import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import APIService from '_app/services/api';
import { FlexCol, FlexRow, HR, Label, MultiImageSlider, PropertyDetailMap, SchoolTable } from '_app/components';
import { FullScreenImageModal } from '_app/components/modals';
import Constants from '_app/constants';
import Utils from '_app/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertyInfoAction } from '_app/store/property/action';
import { getSelectedPropertySelector } from '_app/store/property/selector';
import { LoadingView } from '_app/components/LoadingView';

const PropertyDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showFullImage, setShowFullImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const { item, loading } = useSelector(getSelectedPropertySelector);

  useEffect(() => {
    if (params.propertyId) {
      dispatch(fetchPropertyInfoAction(params.propertyId));
    }
  }, [dispatch]);

  if (loading) {
    return <LoadingView />;
  }

  if (!item) {
    return <div>Property not found</div>;
  }

  return (
    <div className="flex flex-1">
      <FlexCol className="bg-white w-full flex-1 pb-20 items-center">
        <MultiImageSlider
          className="w-full z-10"
          images={item.photos}
          onClick={(selectedImage) => {
            setSelectedImage(selectedImage || 0);
            setShowFullImage(true);
          }}
        />
        <FlexCol className="w-full md:max-w-3xl lg:max-w-4xl z-20 relative px-3 sm:px-3 md:px-0">
          <FlexRow className="absolute -top-12">
            <FlexCol className="bg-black bg-opacity-80 text-white text-3xl font-primary h-12 px-3 inline-block align-middle w-auto justify-center pr-8">
              <span>{`$${item.price.toLocaleString()}`}</span>
            </FlexCol>
          </FlexRow>
          <FlexCol className="py-8 uppercase tracking-widest">
            <div className="text-gray-700 text-2xl leading-8 font-primary-bold">{item.address}</div>
            <div className="text-xl text-gray-600 font-primary-bold leading-8">
              {item.city}, {item.zip}
              <Link to="#" className="ml-2 text-sm normal-case text-blue-600 font-primary">
                Directions
              </Link>
            </div>
          </FlexCol>
          <FlexRow className="p-4 border bg-gray-50">
            <FlexCol className="flex-1">
              <FlexRow>
                <Label label={item.bedrooms} className="mr-2 font-primary-bold text-gray-600 text-base leading-5" />
                <Label label="Bed(s)" className="font-primary-bold text-gray-600 text-base leading-5" />
              </FlexRow>
              <FlexRow>
                <Label label={item.full_bathrooms} className="mr-2 font-primary-bold text-gray-600 text-base leading-5" />
                <Label label="Full Bath(s)" className="font-primary-bold text-gray-600 text-base leading-5" />
              </FlexRow>
              <FlexRow>
                <Label label={item.half_bathrooms} className="mr-2 font-primary-bold text-gray-600 text-base leading-5" />
                <Label label="Half bath(s)" className="font-primary-bold text-gray-600 text-base leading-5" />
              </FlexRow>
            </FlexCol>
            <FlexCol className="flex-1">
              <FlexRow>
                <Label label={item.building_size.toLocaleString()} className="mr-2 font-primary-bold text-gray-600 text-base leading-5" />
                <Label label="Building Sqft." className="font-primary-bold text-gray-600 text-base leading-5" />
              </FlexRow>
              <FlexRow>
                <Label label={item.lot_size.toLocaleString()} className="mr-2 font-primary-bold text-gray-600 text-base leading-5" />
                <Label label="Lot Sqft." className="font-primary-bold text-gray-600 text-base leading-5" />
              </FlexRow>
              <FlexRow>
                <Label label={item.property_type} className="mr-2 font-primary-bold text-gray-600 text-base leading-5" />
              </FlexRow>
            </FlexCol>
          </FlexRow>
          <FlexCol className="my-8 h-72">
            <PropertyDetailMap item={item} />
          </FlexCol>
          <HR />
          <div className="mt-12">
            <Label label="General Description" className="uppercase tracking-widest text-xl text-blue-600" />
            <Label label={Constants.SAMPLE_SENTENCE} className="text-base leading-8 text-gray-600" />
          </div>
          {Utils.generatePropertyGeneralRows(item).map(({ value, label }, index) => (
            <div key={index} className="">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="mt-12">
            <Label label="Taxes & Fees" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyTaxRows(item).map(({ value, label, isLink }, index) => (
            <div key={index} className="">
              {!isLink && <HR className="my-3" />}
              <FlexRow className={isLink ? 'justify-center mt-4' : ''}>
                {isLink && (
                  <Link to={value} rel="noreferrer" target="_blank">
                    <Label label={label} className="font-primary-bold text-base flex-1 text-blue-600" />
                  </Link>
                )}
                {!isLink && (
                  <>
                    <Label label={label} className="font-primary-bold text-base flex-1" />
                    <Label label={value} className="text-base flex-1" />
                  </>
                )}
              </FlexRow>
            </div>
          ))}
          <div className="mt-12">
            <Label label="Room/Lot Size" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyRoomRows(item).map(({ value, label }, index) => (
            <div key={index} className="">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="mt-12">
            <Label label="Interior Features" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyInteriorRows(item).map(({ value, label }, index) => (
            <div key={index} className="">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <div className="mt-12">
            <Label label="Exterior Features" className="font-primary-bold text-xl text-blue-600" />
          </div>
          {Utils.generatePropertyExteriorRows(item).map(({ value, label }, index) => (
            <div key={index} className="">
              <HR className="my-3" />
              <FlexRow>
                <Label label={label} className="font-primary-bold text-base flex-1" />
                <Label label={value} className="text-base flex-1" />
              </FlexRow>
            </div>
          ))}
          <SchoolTable property={item} />
        </FlexCol>
      </FlexCol>
      {showFullImage && (
        <FullScreenImageModal visible={showFullImage} slideIndex={selectedImage} images={item.photos} onClose={() => setShowFullImage(false)} />
      )}
      {loading && <LoadingView />}
    </div>
  );
};

PropertyDetail.getInitialProps = async ({ query }) => {
  try {
    const { data } = await APIService.get(`/properties/${query.propertyId}`);
    return {
      item: data,
    };
  } catch (error) {
    return {
      item: null,
    };
  }
};

export default PropertyDetail;
